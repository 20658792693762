.App {
  .portfolio-container {
    margin: 1% 6% 0 6%;

    hr {
      margin: 4% 0;
    }

    .swirlies {
      position: absolute;
      z-index: 0;
      height: 150px;
      top: 123px;
      left: 0;
    }

    .circles {
      position: absolute;
      z-index: -1;
      top: 58%;
      right: 30%;
    }

    .lower-swirlies {
      position: absolute;
      transform: rotate(180deg);
      z-index: -1;
      height: 150px;
      right: 0px;
      margin-top: 20px;
    }

    .nav-container {
      display: flex;
      justify-content: space-between;

      .home-wrapper {
        font-size: 42px;
        display: flex;
        align-items: center;
        font-style: italic;
        color: #fff;
        text-decoration: none;

        img {
          height: 80%;
          width: auto;
        }
      }
      :hover {
        cursor: pointer;
      }

      .media-wrapper {
        display: flex;
        align-items: center;

        .linked-in {
          margin: 0 25px;
        }

        svg {
          font-size: 40px;
          transition: 0.2s ease-in-out;
          text-decoration: none;
          color: #fff;
          border: 1px transparent;
        }

        svg:hover {
          color: $primary-pink;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }

    .introduction-container {
      width: 100%;
      margin-top: 1%;
      column-gap: 2em;

      @media (min-width: 769px) {
        display: grid;
        grid-template-columns: 1.35fr 0.65fr;
      }

      .introduction-wrapper {
        .intro-header {
          white-space: pre-wrap;
          font-size: 70px;
          font-weight: 800;
          font-style: italic;

          em {
            text-decoration: underline;
            text-decoration-color: $primary-teal;
            text-decoration-thickness: 3px;
            text-underline-offset: 8px;
          }
        }

        .top {
          margin-top: 5%;
        }

        .bottom {
          margin-top: 20px;
        }

        .subtext-wrapper {
          font-size: 18px;
          width: 60%;
          margin: 4% 0 7% 0;
        }

        .resume-wrapper {
          margin-bottom: 20px;

          a {
            font-size: 18px;
            text-decoration: none;
            color: #fff;
            border-bottom: 2px solid $primary-teal;
            transition: 0.2s ease-in-out;
          }

          a:hover {
            margin-left: 15px;
          }
        }

        .view-portfolio-wrapper {
          a {
            transition: 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            width: 250px;
            color: #fff;
            text-decoration: none;
            padding: 1%;
            background-color: $primary-pink;
            border-radius: 8px;
          }

          a:hover {
            padding-left: 20px;
          }

          svg {
            margin-left: 7%;
          }
        }
      }

      .self-img {
        img {
          width: 100%;
          height: auto;
          float: right;
        }
      }
    }

    .skills-header {
      font-size: 45px;
      margin-bottom: 2%;
    }

    .skills-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .skill {
        background-color: $primary-secondary;
        text-align: center;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 2%;
        border-radius: 8px;
        transition: 0.2s ease-in-out;

        svg {
          font-size: 80px;
        }

        .skill-name {
          margin-top: 8px;
          font-size: 16px;
          color: $primary-pink;
        }
      }

      .skill:hover svg {
        -webkit-animation-name: wiggle;
        -ms-animation-name: wiggle;
        -ms-animation-duration: 1000ms;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: 1;
        -ms-animation-iteration-count: 1;
        -webkit-animation-timing-function: ease-in-out;
        -ms-animation-timing-function: ease-in-out;
      }
    }

    .projects-container {
      margin: 5% 0;

      .projects-header {
        font-size: 45px;
        margin-bottom: 2%;
      }

      .projects-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .flip-card {
          background-color: transparent;
          width: 100%;
          height: 300px;
          perspective: 1000px;

          .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;

            .flip-card-front,
            .flip-card-back {
              position: absolute;
              width: 100%;
              height: 100%;
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }

            .flip-card-front {
              background-color: #bbb;
              color: black;

              img {
                width: 100%;
                height: 300px;
                object-fit: cover;
              }
            }

            .flip-card-back {
              background-color: $primary-secondary;
              color: white;
              transform: rotateX(180deg);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h1 {
                margin: 0;
              }

              p {
                color: $primary-pink;
                font-size: 20px;
              }

              .link-wrapper {
                display: flex;
                width: 70%;

                a {
                  position: relative;
                  font-size: 24px;
                  text-decoration: none;
                  color: #fff;
                  border: 1px solid $primary-pink;
                  border-radius: 4px;
                  padding: 2%;
                  transition: 0.2s ease-in-out;
                  margin: 15px;
                  width: 100%;

                  &:hover {
                    background-color: $primary-pink;
                  }
                }
              }
            }
          }
        }

        .flip-card:hover .flip-card-inner {
          transform: rotateX(180deg);
        }
      }
    }
  }

  .footer-container {
    width: 100%;
    background-color: $primary-secondary;

    .copyright-media-wrapper {
      margin: 0 6%;
      height: 100px;
      display: flex;
      justify-content: space-between;

      .copyright {
        display: flex;
        align-items: center;

        em {
          margin-right: 3px;
          color: $primary-pink;
        }
      }

      .media-wrapper {
        display: flex;
        align-items: center;

        .linked-in {
          margin: 0 25px;
        }

        svg {
          font-size: 40px;
          transition: 0.2s ease-in-out;
          text-decoration: none;
          color: #fff;
          border: 1px transparent;
        }

        svg:hover {
          color: $primary-pink;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(1deg);
  }
  25% {
    -ms-transform: rotate(-1deg);
  }
  50% {
    -ms-transform: rotate(1.5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
